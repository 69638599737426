<template>
  <div>
    <div class="list">
      <div class="list-item"
           v-for="(item, index) in worksList"
           :key="index"
           @click="clickItem(item)">
        <div class="item-cover">
          <img :src="item.cover_file_uri"
               alt=""
               class="cover">
          <div class="cover-back">
            <i class="iconfont iconbofang1"></i>
            <div class="cover-title">
              <div class="title-main">
                <div class="date">{{$moment(item.created_at*1000).format('YYYY-MM-DD')}}</div>
                <div class="time">{{item.Newduration}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-info">
          <div class="info-title">{{item.title}}</div>
          <div class="info-avatar">
            <HsAvatar :url="item.user_face"
                      :type="item.cert_type"
                      :status="item.cert_status"></HsAvatar>
            <span class="name">{{item.nick_name}}</span>
          </div>
        </div>
      </div>
      <detail :worksId="worksId"
              :showModal="showModal"
              @closeDetail="closeDetail"
              @examineWorks="examineWorks"></detail>
    </div>
    <div class="no-data"
         v-if="worksList.length<1">
      <img src="@/assets/img/no-data.png"
           alt="">
      <div>暂无数据</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, toRefs, reactive, onMounted, watch } from 'vue'
const detail = defineAsyncComponent(() => import('./detail.vue'))
export default defineComponent({
  emits: ['examineWorks'],
  components: {
    detail
  },
  props: {
    worksList: {
      type: Array,
      default: () => []
    }
  },

  setup (props, context) {
    watch(
      () => props.worksList,
      (count, prevCount) => {
        count.forEach(item => {
          item.Newduration = durationTime(item.duration)
        })
      }
    )
    const data = reactive({
      worksId: 0,
      showModal: false
    })
    onMounted(() => {
    })
    const clickItem = (val) => {
      data.worksId = val.id
      data.showModal = true
    }
    const closeDetail = () => {
      data.showModal = false
    }
    const examineWorks = () => {
      context.emit('examineWorks')
    }
    const durationTime = (seconds = 0) => {
      const _h = Math.floor(seconds / 3600)
      const _m = Math.floor((seconds - _h * 3600) / 60)
      const _s = Math.floor(seconds - _h * 3600 - _m * 60)
      const h = _h >= 10 ? _h : '0' + _h
      const m = _m >= 10 ? _m : '0' + _m
      const s = _s >= 10 ? _s : '0' + _s
      const result = `${m}:${s}`
      if (_h) {
        return `${h}:${result}`
      }
      return result
    }
    return {
      ...toRefs(data),
      closeDetail,
      examineWorks,
      clickItem,
      durationTime
    }
  }
})
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
  padding: 20px 0px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .list-item {
    width: 270px;
    margin-right: 11px;
    margin-bottom: 11px;
    cursor: pointer;
    .item-cover {
      width: 100%;
      height: 162px;
      position: relative;
      background-color: #000;
      .cover {
        width: 94px;
        height: 100%;
        object-fit: cover;
        display: block;
        margin: 0 auto;
      }
      .cover-back {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.1);
        i {
          font-size: 30px;
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .cover-title {
          width: 100%;
          padding: 5px 0px;
          background: rgba($color: #000000, $alpha: 0.5);
          position: absolute;
          bottom: 0;
          .title-main {
            width: 90%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            div {
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
    }
    .item-info {
      padding: 10px;
      background-color: #fff;
      .info-title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-avatar {
        display: flex;
        margin-top: 10px;
        align-items: center;
        span {
          font-size: 12px;
          color: #999999;
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
    &:hover {
      box-shadow: 0px 0px 10px 5px #ccc;
    }
  }
}
.no-data {
  width: 100%;
  background-color: #f4f4f4;
  padding-top: 50px;
  img {
    width: 140px;
    display: block;
    margin: 0 auto;
  }
  div {
    font-size: 14px;
    color: #666;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
